<template>
  <b-modal
    class="swal-popup-custom"
    v-model="show"
    :title="modalTitle"
    ok-title="Fechar"
    ok-variant="primary"
    @ok="closeModal"
    size="lg"
    :scrollable="true"
    :content-class="skin === 'dark' ? 'text-white' : ''"
    hide-footer
  >
    <div v-html="modalContent"></div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";
import {MD_TITLE_ROLE, MD_TITLE_SUB, MD_TITLE_CF, MD_TITLE_CT} from "@/constants/planner_gamification_badges_types";

export default {
  props: {
    badgeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      modalTitle: "",
      modalContent: "",
      skin: "light", // Pode ser dinâmico se a variável skin for gerenciada globalmente
    };
  },
  watch: {
    badgeId(newBadgeId) {
      if (newBadgeId) {
        this.openModal(newBadgeId);
      }
    },
  },
  computed: {
    ...mapGetters({
      roleBadgesModal: types.ROLE_BADGES_MODAL,
      appointmentBadgesModal: types.APPOINTMENT_BADGES_MODAL,
      productsBadgesModal: types.PRODUCTS_BADGES_MODAL,
      totalEarningsBadgesModal: types.TOTAL_EARNINGS_BADGES_MODAL,
    }),
  },
  methods: {
    ...mapActions({
      getRoleBadges: types.GET_ROLE_BADGES_MODAL,
      getAppointmentBadges: types.GET_APPOINTMENT_BADGES_MODAL,
      getProductsBadges: types.GET_PRODUCTS_BADGES_MODAL,
      getTotalEarningsBadges: types.GET_TOTAL_EARNINGS_BADGES_MODAL,
    }),
    openModal(badgeId) {
      this.show = true;
      let badgesAction;
      let titleModal = "";

      if (badgeId === 1) {
        badgesAction = this.getRoleBadges();
        titleModal = MD_TITLE_ROLE;
      } else if (badgeId == 2) {
        badgesAction = this.getAppointmentBadges();
        titleModal = MD_TITLE_SUB;
      } else if ([3, 4, 5, 6].includes(badgeId)) {
        badgesAction = this.getProductsBadges();
        titleModal = MD_TITLE_CF;
      } else {
        badgesAction = this.getTotalEarningsBadges();
        titleModal = MD_TITLE_CT;
      }

      badgesAction
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as agendas da semana. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          let badges;
          if (badgeId === 1) {
            badges = this.roleBadgesModal;
          } else if (badgeId === 2) {
            badges = this.appointmentBadgesModal;
          } else if ([3, 4, 5, 6].includes(badgeId)) {
            badges = this.productsBadgesModal;
          } else {
            badges = this.totalEarningsBadgesModal;
          }

          this.modalTitle = `Metas e conquistas`;
          let badgeContent = `<h4 class="text-center mt-1">${titleModal}</h4><div class="medal-container mt-1">`;
          if (badgeId === 1) {
            badgeContent +=
              '<div style="flex: 1; text-align: center;"><img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/medalhas/Cargos/Cargos.png" style="width: 100%;"></div>';
          } else if ([3, 4, 5, 6].includes(badgeId)) {
            badgeContent += badges
              .map((medal) => {
                const imageUrl = medal.image.imageActive;
                const descriptions = medal.data
                  .map(
                    (desc) =>
                      `
                <div class="medal-description"><div class="medal-description-icon" style="background-image: url('${
                  desc.image
                }')"></div><span style="color: ${
                        desc.value <= medal.image.value ? "#FFFFFF" : "#767676"
                      }">${desc.description}</span></div>
              `
                  )
                  .join("");
                return `
                <div class="medal-item">
                  <div class="medal-icon" style="background-image: url('${imageUrl}')"></div>
                  <div class="medal-title">${medal.title}</div>
                  <div class="medal-descriptions mt-1">
                    ${descriptions}
                  </div>
                </div>
              `;
              })
              .join("");
          } else {
            badgeContent += badges
              .map((medal) => {
                const imageUrl = medal.image;
                return `
                <div class="medal-item">
                  <div class="medal-icon" style="background-image: url('${imageUrl}')"></div>
                  <div class="medal-title">${medal.description}</div>
                </div>
              `;
              })
              .join("");
          }

          badgeContent += "</div>";
          this.modalContent = badgeContent;
        });
    },
    closeModal() {
      this.show = false;
    },
  },
};
</script>

<style>
@media (min-width: 1230px) {
  .modal-lg {
    max-width: 1200px;
  }
}

.modal-header h5 {
  font-size: 20px;
}

.medal-container {
  display: flex;
  justify-content: space-around;
}

.medal-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.medal-icon {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.medal-text {
  font-size: 16px;
}

.medal-title {
  display: inline-block;
  text-align: center;
  background-color: #161d31;
  padding: 5px 10px;
  border-radius: 5px;
}

.medal-description {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}
.medal-description-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 0 10px 0 0;
}
</style>
