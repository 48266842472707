<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <template v-if="isPn">
        <!-- Botões dinâmicos para Medalhas -->
        <b-button
          v-for="button in badgesData"
          :key="button.id"
          variant="primary"
          @click="showModal(button.id)"
          class="ml-2 bagde-button"
        >
          <img
            :src="button.image"
            alt="Badge Image"
            class="bagde-button-image"
          />
        </b-button>
        <!-- Modal para exibir os detalhes do badge -->
        <BadgesModal v-model="showModalFlag" :badgeId="selectedBadgeId" />
      </template>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/modules/dashboard/store/types";
import * as accountTypes from "@/modules/account/store/types";
import { BLink, BNavbarNav } from "bootstrap-vue";
import DarkToggler from "./components/DarkToggler";
import NotificationDropdown from "@/modules/notification/components/NotificationDropdown";
import UserDropdown from "./components/UserDropdown.vue";
import HelpLink from "./components/HelpLink";
import BadgesModal from "@/modules/dashboard/components/BadgesModal.vue";
import { isPnRole } from "@/constants/auth";
import { badgesDataDefault } from "@/constants/planner_gamification_badges_types";

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
    HelpLink,
    BadgesModal,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      badgesData: badgesDataDefault,
      selectedBadgeId: null,
      showModalFlag: false,
    };
  },
  computed: {
    ...mapGetters({
      appointmentBadges: types.APPOINTMENT_BADGES,
      subscriptionBadges: types.SUBSCRIPTION_BADGES,
      roleBadges: types.ROLE_BADGES,
      epBadges: types.EP_BADGES,
      totalEarningsBadges: types.TOTAL_EARNINGS_BADGES,
      consortiumBadges: types.CONSORTIUM_BADGES,
      lifeTotalsBadges: types.LIFE_TOTALS_BADGES,
      user: accountTypes.USER,
    }),
    isPn: function () {
      return isPnRole(this.user?.user_role_id);
    },
  },
  mounted() {
    if (this.isPn) {
      this.$nextTick(() => {
        this.getAllBadges();
      });
    }
  },
  methods: {
    ...mapActions({
      getAppointmentBadges: types.GET_APPOINTMENT_BADGES,
      getSubscriptionBadges: types.GET_SUBSCRIPTION_BADGES,
      getRoleBadges: types.GET_ROLE_BADGES,
      getEpBadges: types.GET_EP_BADGES,
      getTotalEarningsBadges: types.GET_TOTAL_EARNINGS_BADGES,
      getConsortiumBadges: types.GET_CONSORTIUM_BADGES,
      getLifetotalsBadges: types.GET_LIFE_TOTALS_BADGES,
    }),
    getAllBadges() {
      this.getRoleBadges().then(() => {
        this.updateRoleBadgeImage();
      });
      this.getAppointmentBadges().then(() => {
        this.updateAppointmentBadgeImage();
      });
      this.getSubscriptionBadges().then(() => {
        this.updateSubscriptionBadgeImage();
      });
      this.getEpBadges().then(() => {
        this.updateEpBadgeImage();
      });
      this.getLifetotalsBadges().then(() => {
        this.updateVidaBadgeImage();
      });
      this.getConsortiumBadges().then(() => {
        this.updateConsortiumBadgeImage();
      });
      this.getTotalEarningsBadges().then(() => {
        this.updateTotalEarningsBadgeImage();
      });
    },
    updateRoleBadgeImage() {
      const medalhaRole = this.roleBadges.imageActive;
      const roleBadge = this.badgesData.find((badge) => badge.id == 1);
      if (roleBadge) roleBadge.image = medalhaRole;
    },
    updateAppointmentBadgeImage() {
      const medalhaAppointment = this.appointmentBadges.imageActive;
      const appointmentBadge = this.badgesData.find((badge) => badge.id == 2);
      if (appointmentBadge) appointmentBadge.image = medalhaAppointment;
    },
    updateSubscriptionBadgeImage() {
      const medalhaSubscription = this.subscriptionBadges.imageActive;
      const subscriptionBadge = this.badgesData.find((badge) => badge.id == 3);
      if (subscriptionBadge) subscriptionBadge.image = medalhaSubscription;
    },
    updateEpBadgeImage() {
      const medalhaEp = this.epBadges.imageActive;
      const epBadge = this.badgesData.find((badge) => badge.id == 4);
      if (epBadge) epBadge.image = medalhaEp;
    },
    updateVidaBadgeImage() {
      const medalhaVida = this.lifeTotalsBadges.imageActive;
      const vidaBadge = this.badgesData.find((badge) => badge.id == 5);
      if (vidaBadge) vidaBadge.image = medalhaVida;
    },
    updateConsortiumBadgeImage() {
      const medalhaConsortium = this.consortiumBadges.imageActive;
      const consortiumBadge = this.badgesData.find((badge) => badge.id == 6);
      if (consortiumBadge) consortiumBadge.image = medalhaConsortium;
    },
    updateTotalEarningsBadgeImage() {
      const medalhaTotalEarnings = this.totalEarningsBadges.imageActive;
      const totalEarningsBadge = this.badgesData.find((badge) => badge.id == 7);
      if (totalEarningsBadge) totalEarningsBadge.image = medalhaTotalEarnings;
    },
    showModal(badgeId) {
      this.selectedBadgeId = badgeId;
      this.showModalFlag = true;
    },
  },
};
</script>
<style>
.bagde-button {
  padding: 0;
  border: none;
  background-color: transparent !important;
  outline: none;
  box-shadow: none !important;
}
.bagde-button:active,
.bagde-button:focus {
  background-color: transparent !important;
}

.bagde-button-image {
  height: 38px;
}
</style>
