export const badgesDataDefault = [
  {
    id: 1,
    title: "Nível PN",
    image: "https://futurogfp-assets.s3.sa-east-1.amazonaws.com/medalhas/Cargos/PNC.png",
  },
  {
    id: 2,
    title: "Agendamento",
    image: "https://futurogfp-assets.s3.sa-east-1.amazonaws.com/Medalhas-2/ENTREVISTA+AGENDADA/Bronze-EA+locked.png",
  },
  {
    id: 3,
    title: "Assinaturas",
    image: "https://futurogfp-assets.s3.sa-east-1.amazonaws.com/Medalhas-2/ASSINATURAS/Bronze-ASS+locked.png",
  },
  {
    id: 4,
    title: "EPs",
    image: "https://futurogfp-assets.s3.sa-east-1.amazonaws.com/Medalhas-2/ENTREVISTA+PAGA/Bronze-EP+locked.png",
  },
  {
    id: 5,
    title: "Gestão de risco",
    image: "https://futurogfp-assets.s3.sa-east-1.amazonaws.com/Medalhas-2/VIDA/Bronze-VIDA+locked.png",
  },
  {
    id: 6,
    title: "Aquisição de bens",
    image: "https://futurogfp-assets.s3.sa-east-1.amazonaws.com/Medalhas-2/AQUISI%C3%87%C3%83O+DE+BENS/Bronze-AB+locked.png",
  },
  {
    id: 7,
    title: "Faturamento",
    image: "https://futurogfp-assets.s3.sa-east-1.amazonaws.com/Medalhas-2/FATURAMENTO/Bronze-FATURAMENTO+locked000.png",
  },
];

export const MD_TITLE_ROLE = 'Nível PN';
export const MD_TITLE_SUB = 'Agendamento';
export const MD_TITLE_CF = 'Clientes e faturamento';
export const MD_TITLE_CT = 'Faturamento';

